<template>
	<b-modal ref="modal-note" hide-footer title="Poznámka od učiteľa" size='lg' @ok="handleOk">
		<p class="note-text--readonly">{{note}}</p>
	</b-modal>
</template>

<script>
export default {
	props: {
		note: { 
			type: String,
			required: true
		}
	},

	methods: {
		showModal() {
			this.$nextTick(() => {
				this.$refs['modal-note'].show()
			})
		},
		handleOk() {
			this.$nextTick(() => {
				this.$refs['modal-note'].hide()
			})
		}
	}
}
</script>

<style lang="scss" scoped>
	::v-deep .text-dark, ::v-deep .text-truncate, ::v-deep .text-center {
		color: #2B2E4A !important;
	}

	::v-deep .modal-header {
		border-bottom: 0 none;
	}

	::v-deep .light-border {
		border-bottom: 1px solid #CADEEA;
	}

	::v-deep .modal-content {
		top: 150px;
	}

	::v-deep .modal-body {
		p {
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 21px;
			margin-top: auto !important;
			margin-bottom: auto !important;
			align-items: center;

			color: #2B2E4A;
		}
		.modal-box {
			align-items: center;
			width: 569px;
			height: 94px;
			left: 617px;
			top: 847px;

			background: #FFF5D1;
			border: 1px solid #E6D8A7;
			box-sizing: border-box;
			border-radius: 4px;
		}
		.modal-box-text {
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			line-height: 18px;

			letter-spacing: -0.05em;

			color: #CAB979;
		}
	}

	::v-deep .modal-content {
		border-style: solid;
		border-width: 1px;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		border-image: linear-gradient(90.01deg, #0064FF 60.75%, #FB2564 79.89%) 0 0 100% 0/0 0 4.53px 0 stretch;
	}

	.note-text--readonly {
		white-space: pre;
	}
</style>